import http from "./axios";

// 退出登录
export const loginOut = () => {
  return http.json_post("/alpha/user/logout");
};

export const getInfo = () => {
  return http.get("/alpha/user/info");
};
