<template>
  <div style="height: 100%">
    <a-layout id="components-layout-demo-custom-trigger">
      <a-layout-sider
        :trigger="null"
        collapsible
        v-model="collapsed"
        :collapsedWidth="isPc === true ? 80 : 0"
        :style="{
          overflow: 'auto',
          height: '100%',
          position: 'fixed',
          left: 0,
          zIndex: '1000',
        }"
      >
        <a-menu
          theme="dark"
          mode="inline"
          :selectedKeys="[selectedKeys]"
          :openKeys="openKeys"
          @click="selectClick"
          @openChange="onOpenChange"
        >
          <a-sub-menu v-for="item in list" :key="item.url">
            <span slot="title">
              <a-icon :type="item.iconType" />
              <span>{{ item.name }}</span>
            </span>
            <a-menu-item v-for="menu in item.children" :key="menu.url">
              {{ menu.name }}
            </a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout-header
        :class="{
          isheader: !collapsed,
          onheader: collapsed,
          onheaderPc: isPcShow,
        }"
        style="
          background: #fff;
          padding: 0 30px 0 0;
          position: fixed;
          width: 100%;
          z-index: 1;
        "
      >
        <div class="header-cc">
          <div>
            <a-icon
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="() => (collapsed = !collapsed)"
            />
            <span class="title-theme">kafka同步系统</span>
          </div>
          <a-dropdown class="user-name-cc">
            <span
              style="
                cursor: pointer;
                border-left: 1px solid #d3d3d3;
                padding-left: 10px;
                font-size: 16px;
              "
            >
              <a-avatar
                class="avatar"
                size="small"
                shape="circle"
                :src="loginAvatarUrl"
              />
              <span>{{ loginNickName }}</span>
            </span>
            <a-menu style="width: 150px" slot="overlay">
              <a-menu-item>
                <a
                  href="javascript:void(0)"
                  @click="loginOut()"
                  style="
                    display: inline-block;
                    text-align: center;
                    width: 100%;
                    padding: 0;
                    margin: 0;
                  "
                >
                  <a-icon type="poweroff" />
                  <span>退出登录</span>
                </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </a-layout-header>
      <a-layout
        :class="{
          isContent: !collapsed,
          onContent: collapsed,
          onContentPc: isPcShow,
        }"
        :style="{ padding: '24px 16px' }"
      >
        <div style="background: rgb(255, 255, 255); padding: 24px">
          <router-view />
        </div>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import * as api from "../lib/home.js";
export default {
  name: "home",
  data() {
    return {
      collapsed: false,
      openKeys: [],
      list: [
        {
          name: "菜单",
          url: "1",
          iconType: "menu",
          children: [
            {
              name: "增量同步",
              url: "/incrSync",
              iconType: "laptop",
            },
            {
              name: "全量同步",
              url: "/fullSync",
              iconType: "laptop",
            },
            {
              name: "同步规则",
              url: "/syncRuleList",
              iconType: "laptop",
            },
            {
              name: "数据源管理",
              url: "/dataSourceList",
              iconType: "laptop",
            },
          ],
        },
      ],
      rootSubmenuKeys: [],
      loginAvatarUrl: "",
      loginNickName: "管理员",
      orgPassword: "",
      newPassword: "",
      visibleUpdate: false,
      loading: false,
      isPc: false,
    };
  },
  computed: {
    isPcShow() {
      if (this.collapsed && this.isPc) {
        return true;
      }
      return false;
    },
    selectedKeys() {
      let path = "/" + this.$route.path.split("/")[1];
      return path;
    },
  },
  mounted() {
    window.onresize = () => {
      this.IsPC();
    };
    this.IsPC();
    this.getUserInfo();
    let url = window.location.href;
    url = url.split("/");
    this.list.forEach((item) => {
      item.children.forEach((menu) => {
        if (menu.url === "/" + url[3]) {
          this.openKeys.push(item.url);
        }
      });
      this.rootSubmenuKeys.push(item.url);
    });
  },
  methods: {
    // 判断手机or电脑端
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = [
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod",
      ];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      this.isPc = flag; //true为PC端，false为手机端
      //手机端默认收起菜单
      if (!this.isPc) {
        this.collapsed = true;
      }
    },
    // 开启关闭导航栏
    onOpenChange(openKeys) {
      const latestOpenKey = openKeys.find(
        (key) => this.openKeys.indexOf(key) === -1
      );
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    // 导航选择
    selectClick({ item, key, keyPath }) {
      let url = window.location.href;
      url = url.split("/");
      if (key === "/" + url[3]) {
        // 禁止重复跳转相同路由
        return;
      }
      this.$router.replace(key);
    },
    // 获取用户登录信息
    getUserInfo() {
      api.getInfo().then((res) => {
        if (res.result == 200) {
          this.loginNickName = res.userName;
          this.loginAvatarUrl = res.avatar;
        }
      });
    },
    //点击退出登录
    loginOut() {
      api.loginOut().then((res) => {
        if (res.result == 200) {
          let url = window.location.href;
          url = url.split("/");
          url = url[0] + "//" + url[2];
          window.location.href = url;
        }
      });
    },
  },
};
</script>

<style>
.onheader {
  transition: all 0.3s;
}
.onheaderPc {
  padding-left: 80px !important;
}
.isheader {
  padding-left: 200px !important;
}
.isContent {
  margin-left: 200px;
  margin-top: 64px;
}
.onContent {
  margin-top: 64px;
  transition: all 0.3s;
}
.onContentPc {
  margin-left: 80px;
}
.ant-table-body {
  overflow: auto;
}
.ant-table th {
  white-space: nowrap;
}
.ant-table td {
  white-space: nowrap;
  position: relative;
}
#components-layout-demo-custom-trigger {
  height: 100%;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: all 0.3s;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}
#components-layout-demo-custom-trigger .logo {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
}
#components-layout-demo-custom-trigger .logo .logo-image {
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.2);
  margin: 5px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
}
#components-layout-demo-custom-trigger .logo span {
  color: #ffffff;
}
.title-theme {
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
}
.avatar {
  margin-right: 10px !important;
}
.logo .logo-title {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  font-size: 20px;
}
.header-cc {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  height: 64px;
}
.user-name-cc {
  height: 100%;
  vertical-align: initial;
  min-width: 100px;
}
.ant-layout-sider-children {
  text-align: left;
}
</style>
